import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import './layout.css';
import ha from './ha.svg';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query Layout {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        html
        frontmatter {
          templateKey
          contact
          mail
          pdflabel
          pdf
          pdfEn
          haLink
        }
      }
    }
  `);
  return (
    <>
      <header className="fixed z2 t0 m">
        <h1>
          <a
            // className={data.markdownRemark.html ? '' : 'anim'}
            href={`mailto:${data.markdownRemark.frontmatter.mail}`}
          >
            {data.markdownRemark.frontmatter.contact}
          </a>
        </h1>
        <p>
          <a href="https://www.orianedechery.com/">↻</a>
        </p>
        <h2>
          {data.markdownRemark.frontmatter.pdflabel}{' '}
          {data.markdownRemark.frontmatter.pdf && (
            <a
              href={data.markdownRemark.frontmatter.pdf}
              target="_blank"
              rel="noopener noreferrer"
            >
              FR
            </a>
          )}
          {data.markdownRemark.frontmatter.pdfEn && (
            <a
              href={data.markdownRemark.frontmatter.pdfEn}
              target="_blank"
              rel="noopener noreferrer"
            >
              /EN
            </a>
          )}
        </h2>
        {data.markdownRemark.frontmatter.haLink && (
          <h3>
            <a
              href={data.markdownRemark.frontmatter.haLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="ha" src={ha} alt="Home affairs" width="100" />
            </a>
          </h3>
        )}
        <br />
        {data.markdownRemark.html && (
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        )}
      </header>
      <main role="main">{children}</main>
    </>
  );
};
export default Layout;
